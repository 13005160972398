#div_alert_container {
  width: 100%;
}

.group:before, .group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1;
  /* For IE 6/7 */
}

body[dir=ltr] .text-align-right {
  text-align: right !important;
}
body[dir=rtl] .text-align-right {
  text-align: left !important;
}